import React from "react";

import { AppBar, Box, Container, Grid, Typography } from "@mui/material";

import { isMobile } from "react-device-detect";

//this is a centered layout for when an object only needs to be in the center i.e a login
const UnAuthorisedLayout = ({
  alignItems = "center",
  justify = "center",
  children,
}) => (
  <>
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems={alignItems}
      justify={justify}
      overflow="auto"
      sx={{ mb: 20 }}
    >
      <AppBar position="fixed" color="primary" enableColorOnDark>
        <Typography variant="h6" sx={{ p: 1, fontWeight: "bold" }}>
          Crown Downloads
        </Typography>
      </AppBar>
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: { justify },
          alignItems: { alignItems },
          height: isMobile ? "100%" : "100vh",
          overflow: "auto",
          marginTop: 10,
        }}
      >
        {children}
      </Container>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          p: 1,
          width: "100%",
          backgroundColor: "#f8f8f8",
          borderTop: "1px solid #e7e7e7",
        }}
      >
        <Typography variant="body2" sx={{ textAlign: "center" }}>
          Crown Imperial is manufactured by Crown Products (Kent) Ltd. Copyright
          2001-{`${new Date().getFullYear()}`}, Crown Products (Kent) Ltd. All
          Rights Reserved.
        </Typography>
      </Box>
    </Grid>
  </>
);

export default UnAuthorisedLayout;
